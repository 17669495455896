import axios from 'axios';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useAppContext } from './context';
import { isAdmin } from './tools';

const getActu = async (id) => {
  const { data } = await axios.get(`/api/wagtail/v2/pages/${id}`);
  return data;
};

const getActus = async (pageParam, category, search) => {
  const offset = pageParam * 10;
  let slug = `/api/wagtail/v2/pages/?type=blogactu.actu&fields=*&offset=${offset}&limit=10&order=-first_published_at${
    category ? `&categories=${category}` : ''
  }`;
  if (search) {
    slug += `&search=${search}`;
  }
  const { data } = await axios.get(slug);
  return data;
};

const getActusCategories = async () => {
  const { data } = await axios.get(`/api/wagtail/v2/actus_categories/`);
  return data;
};

const getAdvertisements = async (pageParam, search) => {
  const { data } = await axios.get(
    `/api/advertisements/?page=${pageParam + 1}&search=${search}`,
  );
  return data;
};

const getComments = async (id) => {
  const { data } = await axios.get(`/api/comments/?page_id=${id}`);
  return data;
};

const getCounters = async () => {
  const { data } = await axios.get(`/api/user/page/counters/`);
  return data;
};

const getCurrentHoldingHistory = async (pageParam) => {
  const { data } = await axios.get(
    `/api/v1/holding/holding-history/?page=${pageParam + 1}`,
  );
  return data;
};

export const getCurrentUser = async () => {
  const { data } = await axios.get(`/api/auth/user/`);
  return data;
};

const getCurrentUserProfile = async () => {
  const { data } = await axios.get(`/api/user/users/profile/`);
  return data;
};

const getDiagnosis = async (holdingId) => {
  const { data } = await axios.get(
    `/api/diagnosis/diagnoses/holding/${holdingId}/`,
  );
  return data;
};

export const getDiagnosisPDF = async (holdingIds) => {
  const { data } = await axios.get(`/pdf/pdf-export/`, {
    params: {
      holding_ids: holdingIds,
    },
  });
  return data;
};

const getDiagnosisReferences = async () => {
  const { data } = await axios.get(`/api/diagnosis/diagnoses/references/`);
  return data;
};

const getDocumentReceivedDownload = async (documentId) => {
  const { data } = await axios.get(`/api/documents/download/${documentId}`);
  return data;
};

const getDocumentsReceived = async (
  pageParam,
  producerCode,
  documentType,
  category,
  search,
  share,
) => {
  let url;
  if (pageParam === 1) {
    url = `/api/documents/documents/?page=${pageParam}&producer_code=${producerCode}&shared=${share}&document_type=${documentType}&category=${category}&search=${search}`;
  } else {
    url = pageParam;
  }
  const { data } = await axios.get(url);
  return data;
};

const getDocumentsReceivedCategories = async () => {
  const { data } = await axios.get(`/api/documents/categories/`);
  return data;
};

const getDocumentTypes = async () => {
  const { data } = await axios.get(`/api/documents/document-types/`);
  return data;
};

const getEvents = async () => {
  const { data } = await axios.get(
    `/api/wagtail/v2/pages/?type=blogevent.event&fields=*&limit=200&order=start_date`,
  );
  return data;
};

const getHolding = async (id) => {
  const { data } = await axios.get(`/api/v1/holding/holdings/${id}/`);
  return data;
};

const getHoldings = async (pageParam, search, isLabelling, isAuditRequired) => {
  const { data } = await axios.get(
    `/api/v1/holding/holdings/?page=${
      pageParam + 1
    }&search=${search}&is_labelling=${isLabelling}&is_audit_required=${isAuditRequired}`,
  );
  return data;
};

const getHoldingHistory = async (pageParam, id, search) => {
  const { data } = await axios.get(
    `/api/v1/holding/holdings/${id}/history/?page=${
      pageParam + 1
    }&search=${search}`,
  );
  return data;
};

const getImportantInformation = async (id) => {
  const { data: importantInformation } = await axios.get(
    `/api/wagtail/v2/pages/${id}/?type=bloginformation.importantinformation&fields=*,-summary`,
  );
  const documentLinks = importantInformation.body.match(
    /<a id="(\d*)" linktype="document">/g,
  );
  if (documentLinks) {
    // eslint-disable-next-line no-restricted-syntax
    for (const documentLink of documentLinks) {
      const documentId = documentLink.split('<a id="')[1].split('"')[0];
      // eslint-disable-next-line no-await-in-loop
      const { data: document } = await axios.get(
        `/api/wagtail/v2/documents/${documentId}/`,
      );
      importantInformation.body = importantInformation.body.replace(
        `<a id="${documentId}" linktype="document">`,
        `<a href="${document.meta.download_url}" target="_blank">`,
      );
    }
  }
  return importantInformation;
};

const getImportantInformations = async (search) => {
  let slug;
  if (search) {
    slug = `/api/wagtail/v2/pages/?type=bloginformation.importantinformation&fields=*,-body&order=-first_published_at&search=${search}`;
  } else {
    slug = `/api/wagtail/v2/pages/?type=bloginformation.importantinformation&fields=*,-body&order=-first_published_at`;
  }
  const { data } = await axios.get(slug);
  return data;
};

const getMessage = async (holdingId, messageId) => {
  const { data } = await axios.get(
    `/api/message/messages/holding/${holdingId}/message/${messageId}/`,
  );
  return data;
};

const getMessages = async (pageParam, holdingId, search) => {
  let url;
  if (isAdmin() && !holdingId) {
    url = `/api/message/messages/?page=${pageParam + 1}&search=${search}`;
  } else {
    url = `/api/message/messages/holding/${holdingId}/?page=${
      pageParam + 1
    }&search=${search}`;
  }
  const { data } = await axios.get(url);
  return data;
};

const getMyDocuments = async (
  attachments,
  pageParam,
  producerCode,
  search,
  userDoc,
) => {
  let url;
  if (pageParam === 1) {
    url = `/api/documents/documents/?page=${pageParam}&producer_code=${producerCode}&attachments=${attachments}&user_doc=${userDoc}&search=${search}`;
  } else {
    url = pageParam;
  }
  const { data } = await axios.get(url);
  return data;
};

const getParcels = async (holdingId) => {
  const { data } = await axios.get(
    `/api/v1/parcel/parcels/holding/${holdingId}/`,
  );
  return data;
};

const getPlanning = async (holdingId, year) => {
  const { data } = await axios.get(
    `/api/v1/parcel/parcels/planning/${holdingId}/year/${year}/`,
  );
  return data;
};

const getSettings = async () => {
  const { data } = await axios.get(`/api/settings/settings/`);
  return data;
};

const getSurvey = async (id) => {
  const { data } = await axios.get(
    `/api/wagtail/v2/pages/${id}/?type=blogsurvey.survey&fields=*,-summary`,
  );
  return data;
};

const getSurveys = async (holdingId) => {
  if (holdingId) {
    const { data } = await axios.get(
      `/api/wagtail/v2/surveys/?holding_id=${holdingId}&order=-first_published_at`,
    );
    return data;
  }
  return null;
};

const getVideos = async (pageParam, search) => {
  const offset = pageParam * 10;
  let slug = `/api/wagtail/v2/pages/?type=blogvideo.video&fields=*&offset=${offset}&limit=10&order=-first_published_at`;
  if (search) {
    slug += `&search=${search}`;
  }

  const { data } = await axios.get(slug);
  return data;
};

export const useActuQuery = (id) =>
  useQuery(['actu', id], () => getActu(id), {
    refetchOnWindowFocus: false,
  });

export const useActusQuery = (category, search) =>
  useInfiniteQuery(
    ['actus', category, search],
    ({ pageParam = 0 }) => getActus(pageParam, category, search),
    {
      refetchInterval: 1000 * 3600,
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage, pages) =>
        lastPage.meta.total_count > pages.length * 10
          ? pages.length
          : undefined,
    },
  );

export const useActusCategoriesQuery = () =>
  useQuery(['actus-categories'], () => getActusCategories(), {
    refetchOnWindowFocus: false,
  });

export const useAdvertisementsQuery = (search) =>
  useInfiniteQuery(
    ['advertisements', search],
    ({ pageParam = 0 }) => getAdvertisements(pageParam, search),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.count > allPages.length * 20 ? allPages.length : undefined,
    },
  );

export const useCommentsQuery = (id) =>
  useQuery(['comments', id], () => getComments(id));

export const useCountersQuery = () =>
  useQuery(['counters'], () => getCounters(), {
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 30,
  });

export const useCurrentHoldingHistoryQuery = () =>
  useInfiniteQuery(
    ['current-holding-history'],
    ({ pageParam = 0 }) => getCurrentHoldingHistory(pageParam),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.count > allPages.length * 20 ? allPages.length : undefined,
    },
  );

export const useCurrentUserQuery = () =>
  useQuery(['current-user'], () => getCurrentUser());

export const useCurrentUserProfileQuery = () =>
  useQuery(['current-user-profile'], () => getCurrentUserProfile());

export const useDiagnosisQuery = (holdingId) => {
  const { setAutoDiagData } = useAppContext();

  return useQuery(['diagnosis', holdingId], () => getDiagnosis(holdingId), {
    onSuccess: (data) => {
      setAutoDiagData(data.content);
    },
  });
};

export const useDiagnosisPDFQuery = (holdingIds) =>
  useQuery(['diagnosis-pdf', holdingIds], () => getDiagnosisPDF(holdingIds));

export const useDiagnosisReferencesQuery = () =>
  useQuery(['diagnosis-references'], () => getDiagnosisReferences());

export const useDocumentReceivedDownloadQuery = (documentId) =>
  useQuery(['document-received-download', documentId], () =>
    getDocumentReceivedDownload(documentId),
  );

export const useDocumentsReceivedQuery = (
  producerCode,
  documentType,
  category,
  search,
  share,
) =>
  useInfiniteQuery(
    ['documents-received', producerCode, documentType, category, search, share],
    ({ pageParam = 1 }) =>
      getDocumentsReceived(
        pageParam,
        producerCode,
        documentType,
        category,
        search,
        share,
      ),
    {
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage) => lastPage.next,
    },
  );

export const useDocumentsReceivedCategoriesQuery = () =>
  useQuery(['documents-received-categories'], () =>
    getDocumentsReceivedCategories(),
  );

export const useDocumentTypesQuery = () =>
  useQuery(['document-types'], () => getDocumentTypes());

export const useEventsQuery = () =>
  useQuery(['events'], () => getEvents(), {
    select: (data) => {
      const newData = data;
      const futureEvents = data.items.filter(
        (event) => Date.parse(event.start_date) >= Date.now(),
      );
      // eslint-disable-next-line prefer-destructuring
      newData.highLightedEvent = futureEvents[0];
      return newData;
    },
  });

export const useHoldingQuery = (id) =>
  useQuery(['holding', id], () => getHolding(id), {
    onSuccess: (data) =>
      localStorage.setItem('producerCode', data.producer_code),
  });

export const useHoldingHistoryQuery = (id, search) =>
  useInfiniteQuery(
    ['holding-history', id, search],
    ({ pageParam = 0 }) => getHoldingHistory(pageParam, id, search),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.count > allPages.length * 20 ? allPages.length : undefined,
    },
  );

export const useHoldingsQuery = (search, isLabelling, isAuditRequired) =>
  useInfiniteQuery(
    ['holdings', search, isLabelling, isAuditRequired],
    ({ pageParam = 0 }) =>
      getHoldings(pageParam, search, isLabelling, isAuditRequired),
    {
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.count > allPages.length * 20 ? allPages.length : undefined,
    },
  );

export const useImportantInformationQuery = (id) =>
  useQuery(['important-information', id], () => getImportantInformation(id));

export const useImportantInformationsQuery = (search) =>
  useQuery(
    ['important-informations', search],
    () => getImportantInformations(search),
    {
      refetchOnWindowFocus: true,
    },
  );

export const useMessageQuery = (holdingId, messageId) => {
  const queryClient = useQueryClient();
  return useQuery(
    ['message', holdingId, messageId],
    () => getMessage(holdingId, messageId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['messages']);
      },
    },
  );
};

export const useMessagesQuery = (holdingId, search) =>
  useInfiniteQuery(
    ['messages', holdingId, search],
    ({ pageParam = 0 }) => getMessages(pageParam, holdingId, search),
    {
      refetchOnWindowFocus: true,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.count > allPages.length * 20 ? allPages.length : undefined,
    },
  );

export const useMyDocumentsQuery = (
  attachments,
  producerCode,
  search,
  userDoc,
) =>
  useInfiniteQuery(
    ['my-documents', attachments, producerCode, search, userDoc],
    ({ pageParam = 1 }) =>
      getMyDocuments(attachments, pageParam, producerCode, search, userDoc),
    {
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60,
      getNextPageParam: (lastPage) => lastPage.next,
    },
  );

export const useParcelsQuery = (holdingId) =>
  useQuery(['parcels', holdingId], () => getParcels(holdingId));

export const usePlanningQuery = (holdingId, year) => {
  const { setNotCurrentYearPlanningData, setPlanningData } = useAppContext();
  return useQuery(
    ['planning', holdingId, year],
    () => getPlanning(holdingId, year),
    {
      staleTime: 0,
      onSuccess: (data) => {
        const formatedData = {
          ...data,
          totalVolume: data.production_volume.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.milk_volume,
            0,
          ),
        };
        if (year === new Date().getFullYear()) {
          setPlanningData(formatedData);
        } else {
          setNotCurrentYearPlanningData(formatedData);
        }
      },
    },
  );
};

export const useSettingsQuery = () =>
  useQuery(['settings'], () => getSettings());

export const useSurveyQuery = (id) =>
  useQuery(['survey', id], () => getSurvey(id));

export const useSurveysQuery = (holdingId) =>
  useQuery(['surveys', holdingId], () => getSurveys(holdingId), {
    refetchOnWindowFocus: true,
  });

export const useVideosQuery = (search) =>
  useInfiniteQuery(
    ['videos', search],
    ({ pageParam = 0 }) => getVideos(pageParam, search),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) =>
        lastPage.meta.total_count > pages.length * 10
          ? pages.length
          : undefined,
    },
  );
